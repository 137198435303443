.custom-file-container {
  box-sizing: border-box;
  position: relative;
  display: block; }
  .custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px; }
    .custom-file-container__custom-file:hover {
      cursor: pointer; }
    .custom-file-container__custom-file__custom-file-input {
      box-sizing: border-box;
      min-width: 14rem;
      max-width: 100%;
      height: calc(2.25rem + 2px);
      margin: 0;
      opacity: 0; }
      .custom-file-container__custom-file__custom-file-input:focus ~ span {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color; }
    .custom-file-container__custom-file__custom-file-control {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 5;
      height: calc(2.25rem + 2px);
      padding: .5rem .75rem;
      overflow: hidden;
      line-height: 1.5;
      color: #333;
      user-select: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #c0c0af;
      border-radius: .25rem; }
      .custom-file-container__custom-file__custom-file-control__button {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 6;
        display: block;
        height: calc(2.25rem + 2px);
        padding: .5rem .75rem;
        line-height: 1.25;
        color: #333;
        background-color: #EDEDE8;
        border-left: 1px solid #c0c0af;
        box-sizing: border-box; }
  .custom-file-container__image-preview {
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: scroll; }
  .custom-file-container__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736059%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25); }
    .custom-file-container__image-multi-preview__single-image-clear {
      left: -6px;
      background: #EDEDE8;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      margin-top: -6px;
      box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25); }
      .custom-file-container__image-multi-preview__single-image-clear:hover {
        background: #cbcbbd;
        cursor: pointer; }
      .custom-file-container__image-multi-preview__single-image-clear__icon {
        color: #6a6a53;
        display: block;
        margin-top: -2px; }
